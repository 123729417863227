

class SensorService{
    async GetTemperature(){

        const response = await fetch(`https://iot-api.oslofjord.com/api/water-sensor/latest?devicename=water-sensor-brygge`)
        var data = await response.json();
        return data;
    }
}

const sensorService = new SensorService();
export default sensorService