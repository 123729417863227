import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from "i18next-browser-languagedetector";

i18n.use(detector).use(initReactI18next).init({
  resources: {
    en: {
      translations: require('./locales/en/translations.json')
    },
    no: {
      translations: require('./locales/no/translations.json')
    }
  },
  fallbackLng: 'en',
  supportedLngs: ['en', 'no'],
  ns: ['translations'],
  defaultNS: 'translations',
});

i18n.languages = ['en', 'no'];

export default i18n;