import './App.css';
import sensorService from './services/sensorService.ts'
import {useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";


function App() {
  var [data, setData] = useState(null)
  var [lastMeasured, setLastMeasured ]= useState(0);
  const { t } = useTranslation();



  useEffect(() => {
    if(data == null){
    TemperatureData();
    }
    if(data != null){
      ConvertToReadableTime(data.publishedAt);

      var interval = setInterval(() => {
        ConvertToReadableTime(data.publishedAt);
        if(lastMeasured >= 20){  
          setData(null);
        }
        }, 60000);
      
      return () => {
        clearInterval(interval);
      };
    }
  },[data, lastMeasured])




  
  document.body.style.overflow = "hidden";
  window.addEventListener("resize", resize);

  if(data != null){
    return (
      <div className="App" onLoad={resize}>
        <header className="App-header">
          <img className="top-right "src="/sun.png" alt=""></img>
          <img id= "beach-br" className="bottom-right" src="/beach.png" alt=""></img>
          <img id="beach-bl" className="bottom-left" src="/beach.png" alt=""></img>
          <div id="fillerDiv" className="bottom"></div>
          <h1 style={{margin: 5}}>{t('waterTemperature')}</h1>
          <h2 style={{margin: 5}}>{data.data.temperature}℃</h2> 
          <p>{t('lastMeasurement', {val : lastMeasured})}</p>
        </header>
        
      </div>
    );
  }


  function resize(){
    var documentWidth = document.body.clientWidth;
    var documentHeight = document.body.clientHeight;

    var imageWidth = (documentWidth * 15) / documentWidth;
    var imageHeight = (documentHeight * 50) / documentHeight;

    document.getElementById("beach-br").style.width = imageWidth  + "%";
    document.getElementById("beach-bl").style.width = imageWidth + "%";

    document.getElementById("beach-br").style.height = imageHeight + "%";
    document.getElementById("beach-bl").style.height = imageHeight  + "%";

    document.getElementById("fillerDiv").style.width = 100 - (imageWidth * 2) + "%";
    document.getElementById("fillerDiv").style.height = imageHeight * 0.15 + "%";
  }



  async function TemperatureData(){
    var resp = await sensorService.GetTemperature();
    setData(resp);
  };


  function ConvertToReadableTime(publishedDate){
    var now = new Date();
    var nowUtcEpoch = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(),
    now.getUTCDate(), now.getUTCHours(),
    now.getUTCMinutes(), now.getUTCSeconds());

    var year = publishedDate.substring(0, publishedDate.indexOf("-"));
    publishedDate = publishedDate.slice(5,publishedDate.length)

    var month = publishedDate.substring(0, publishedDate.indexOf("-"));
    publishedDate = publishedDate.slice(3,publishedDate.length)
    
    var day = publishedDate.substring(0, publishedDate.indexOf("T"));
    publishedDate = publishedDate.slice(3,publishedDate.length)

    var time = publishedDate.substring(0, publishedDate.indexOf("."));

    var hour = time.substring(0, time.indexOf(":"));
    time = time.slice(3,time.length)

    var minutes = time.substring(0, time.indexOf(":"));

    var seconds = time.slice(3,time.length)


    var transformedDate = new Date(year, month-1, day, hour, minutes, seconds);
    var transformedDateEpoch = Date.UTC(transformedDate.getFullYear(), transformedDate.getMonth(),
    transformedDate.getDate(), transformedDate.getHours(),
    transformedDate.getMinutes(), transformedDate.getSeconds()); 

    var diffMs = (nowUtcEpoch - transformedDateEpoch);

    var totalMinutesSinceUpdate = Math.floor((diffMs / 60000))
    var totalHoursSinceUpdate = Math.floor(totalMinutesSinceUpdate / 60)

    var minutesSinceUpdate = Math.floor((diffMs / 60000) % 60)
    var hoursSinceUpdate = Math.floor((diffMs / 60000 * 60) % 60)

    setLastMeasured(totalMinutesSinceUpdate);
  }
}

export default App;
